document.getElementById('contactbutton').onclick = function() {
	if (document.getElementById('contactform').style.display == "block") {
		document.getElementById('contactform').style.display = "none"
		document.getElementById('contactbutton').textContent = "Contact"
		document.getElementById('contactbutton').classList.remove("btn-danger")
		document.getElementById('contactbutton').classList.add("btn-success")

	}
	else {
		document.getElementById('contactform').style.display ="block"
		document.getElementById('contactbutton').textContent = "Don't contact"
		document.getElementById('contactbutton').classList.remove("btn-success")
		document.getElementById('contactbutton').classList.add("btn-danger")
	}
}